'use client';

import React, {useCallback, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import Image from "next/image";
import JSConfetti from 'js-confetti'
import {PopupButton} from "@typeform/embed-react";
import {JoinUs} from "@/gql/joinUs";
import {useTranslation} from "@/i18n/client";
import "./JoinUsSection.scss"
import BackIcon from "@/components/Icons/BackIcon";
import NextIcon from "@/components/Icons/NextIcon";

interface JoinUsSectionProps {
    sectionName: string,
    joinUs: JoinUs
}
const JoinUsSection = ({sectionName, joinUs}:JoinUsSectionProps) => {

    const [lastAnswer, setLastAnswer] = useState(-1);
    const [swiper, setSwiper] = useState<any>(null);
    const [response, setResponse] = useState(null);
    const [advice, setAdvice] = useState<string>("");
    const {t} = useTranslation("common");

    const goNext = useCallback(() => {
        const questionIdx = swiper.activeIndex -1;
        if(questionIdx > lastAnswer) return;
        swiper && swiper.slideNext();
    }, [swiper, lastAnswer]);

    const goBack = useCallback(() => {
        if(swiper.activeIndex === 0) return;
        swiper && swiper.slidePrev();
    },[swiper]);

    const updateResponse = useCallback((r:any) => {
        setResponse(r);
        const questionIdx = swiper.activeIndex -1;
        const question = joinUs.questions[questionIdx];
        const answer = question.answers[r];
        if(answer.valid) {
            if(questionIdx > lastAnswer) setLastAnswer(questionIdx);
            setAdvice(t("well-done"));
            const canvas = swiper.slides[swiper.activeIndex].getElementsByTagName('canvas')[0];
            const jsConfetti = new JSConfetti({canvas});
            jsConfetti.addConfetti({
                confettiRadius: 6,
                confettiNumber: 500,
                confettiColors: [
                    '#ff005d', '#8118f3', '#ffa300', '#150047', '#ffffff',
                ]
            }).then(() => {
                if(swiper.activeIndex - 1 === questionIdx) {
                    swiper.slideNext()
                }
            });
        } else {
            setAdvice(answer.comment || t('try-again'));
        }
    }, [setAdvice, setLastAnswer, swiper, joinUs.questions, lastAnswer, t, goNext]);

    return <section id={sectionName} className={"JoinUsSection"}>
        <Swiper allowTouchMove={false} onSwiper={setSwiper} slidesPerView={1}>

            <SwiperSlide>
                <h2>{joinUs.title}</h2>
                <p>{joinUs.description}</p>
                <div className={"JoinUsSection__Buttons"}>
                    <button className={"button red"}
                            onClick={goNext}>
                        {t("joinus-quizz-start")}
                    </button>
                </div>
            </SwiperSlide>

            {joinUs.questions.map((question:any,i:number) => <SwiperSlide key={i}>
                <canvas/>
                <p>{question.question}</p>
                <p>
                    {question.answers.map((answer:any,j:number) =>
                        <button
                            key={j}
                            className={"button wired white "+(
                                !swiper || i !== swiper.activeIndex -1 || response !== j ? '' :
                                    (answer.valid?'JoinUsSection__Good':'JoinUsSection__Bad')
                            )}
                            onClick={() => updateResponse(j)}
                        >
                        {answer.answer}
                    </button>)}
                </p>
                <div className={"JoinUsSection__Pagination"}>
                    <div className={"JoinUsSection__Pagination__Button"}
                         onClick={goBack}>
                        <BackIcon/>
                    </div>
                    {(i + 1) + '/' + joinUs.questions.length}
                    <div className={"JoinUsSection__Pagination__Button" + (lastAnswer < i ? "-disabled" : "")}
                         onClick={goNext}>
                        <NextIcon/>
                    </div>
                </div>
            </SwiperSlide>)}

            <SwiperSlide>
                <h2><a href={"mailto:" + joinUs.mail}>{joinUs.mail}</a></h2>
                <PopupButton id={joinUs.typeForm} className="button red big-button" >
                    {t('joinus-candidature')}
                </PopupButton>
            </SwiperSlide>
        </Swiper>


    </section>
}

export default JoinUsSection;