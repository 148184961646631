'use client'
import {PopupButton} from "@typeform/embed-react";
import React from "react";
import {useTranslation} from "@/i18n/client";


export default function FooterContactButton() {
    const {t} = useTranslation("common");
    return <PopupButton id={"Pk2XFuW1"} className="button red">
        {t('contact')}
    </PopupButton>
}