'use client'
import React, {createContext} from "react";


interface MenuItem {
    id: string;
    title: string;
    href: string;
    extraCss?: string;
    enabled: boolean;
}


const Context = createContext<{
    menuItems: MenuItem[],
    setActiveMenu: (m:string|null)=>void,
    activeMenu: string|null
}>({
    menuItems: [],
    setActiveMenu: ()=>{},
    activeMenu: null
});


export function MenuProvider({
    children,
    menuItems
}: {
    children: React.ReactNode;
    menuItems: MenuItem[];
}) {
    const [activeMenu, setActiveMenu] = React.useState<string|null>(null);
    return <Context.Provider value={{
        menuItems,
        setActiveMenu,
        activeMenu
    }}>{children}</Context.Provider>;
}

export function useMenu() {
    return React.useContext(Context);
}