


export default function BackIcon({width=43, height=43}) {
    return <svg width={width} height={height} viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="21.5" cy="21.5" r="21" transform="rotate(-180 21.5 21.5)" stroke="currentColor"/>
        <path
            d="M10.6464 20.6464C10.4512 20.8417 10.4512 21.1583 10.6464 21.3536L13.8284 24.5355C14.0237 24.7308 14.3403 24.7308 14.5355 24.5355C14.7308 24.3403 14.7308 24.0237 14.5355 23.8284L11.7071 21L14.5355 18.1716C14.7308 17.9763 14.7308 17.6597 14.5355 17.4645C14.3403 17.2692 14.0237 17.2692 13.8284 17.4645L10.6464 20.6464ZM32 20.5L11 20.5L11 21.5L32 21.5L32 20.5Z"
            fill="currentColor"/>
    </svg>

}