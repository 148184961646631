'use client'

import React, { useEffect, useState} from "react";
import Link from "next/link";
import Image from "next/image";
import {PopupButton} from "@typeform/embed-react";
import {useTranslation} from "@/i18n/client";
import "./Navbar.scss";
import {useMenu} from "@/hooks/menu-provider";

const Navbar = ({logoUrl}:{logoUrl:string}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [markerPosition, setMarkerPosition] = useState("378px");
  const {menuItems, activeMenu} = useMenu();

  const {t} = useTranslation("common");

  useEffect(() => {
      const menu = document.getElementById("nav-"+activeMenu);
      if(menu) {
          const bounds = menu.getBoundingClientRect();
          const offset = Math.round(bounds.x + bounds.width / 2);
          setMarkerPosition(offset+'px');
      }
      else{
          setMarkerPosition('-10px');
      }
  }, [activeMenu]);


    return <nav className={showMenu ? "Navbar Navbar__responsive" : "Navbar"}>
          <div className="Navbar__responsive-menu">
              <div
                  className={"Navbar__hamburger "+ (showMenu ? "Navbar__hamburger--animation" : "")}
                  onClick={() => {
                      setShowMenu(!showMenu);
                  }}
              >
                  <span></span>
                  <span></span>
                  <span></span>
              </div>
          </div>
          <div className="Navbar__marker-progress">
              <div className="Navbar__marker-progress-dot" style={{ left: markerPosition }}/>
          </div>
        {logoUrl && <div className="Navbar__logo">
            <Link href="/">
                <Image src={logoUrl} alt={"Logo Yzy digital"}
                       width={200}
                       height={100}
                       priority={true}
                       onClick={() => {
                           setShowMenu(false);
                       }}
                />
            </Link>
        </div>}
          <ul
            className="Navbar__links" onClick={() => {setShowMenu(false);}}>
            {menuItems.map((item, i) =>
                  <li key={i} className={[
                      "Navbar__link",
                      (item.extraCss||''),
                      (activeMenu === item.id) && 'Navbar__link--activated'
                  ].join(' ')} id={"nav-"+item.id}>
                    <Link
                      className="Navbar__animation"
                      href={item.href}
                      key={i}
                    >
                      {item.title}
                    </Link>
                  </li>
            )}
              <li>
                  <PopupButton id={"Pk2XFuW1"} className="button red Navbar__link Navbar__link__contact">
                      {t('contact')}
                  </PopupButton>
              </li>
          </ul>
        </nav>
};

export default Navbar;

/*


          {!breakpoints.l && (

      )}
 */


/*



   return <nav className={"bg-white border-gray-200 dark:bg-dark"}>
       <div className={"max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 h-100"}>
           <Link href="/" className={"flex items-center"}>
               <Image src={"/images/logo-header.svg"} alt={"Logo Yzy digital"}
                      width={200}
                      height={100}
                      className={"h-8"}
                      onClick={() => {
                          setShowMenu(false);
                      }}
               />
           </Link>
           <div className="hidden w-full md:block md:w-auto">
               <ul className="font-light flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">

                   {menuItems.map((item, i) => (
                       <li key={i}>
                           <Link
                               className="block py-2 pl-3 pr-4 bg-transparent text-gray-400"
                               href={item.href}
                               key={i}
                           >
                               {item.title}
                           </Link>
                       </li>
                   ))}
               </ul>
           </div>
       </div>
   </nav>
                       <li>
                       <a href="#"
                          className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                          aria-current="page">Home</a>
                   </li>
                   <li>
                       <a href="#"
                          className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">About</a>
                   </li>
                   <li>
                       <a href="#"
                          className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Services</a>
                   </li>
                   <li>
                       <a href="#"
                          className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Pricing</a>
                   </li>
                   <li>
                       <a href="#"
                          className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</a>
                   </li>

    */



/*
  function handleScrollMarkerPosition() {
    homeMenu.forEach((element) => {
      if (!element?.ref?.current) return;
      sectionDiv = element?.ref?.current;
      sectionHeight = sectionDiv.getBoundingClientRect().height;
      sectionTopPosition = sectionDiv.getBoundingClientRect().top;
      sectionBottomPosition = sectionDiv.getBoundingClientRect().bottom;
      if (
        sectionTopPosition < sectionHeight / 2 + 100 &&
        sectionBottomPosition > sectionHeight / 2 - 100
      ) {
        switch (sectionDiv.getAttribute("id")) {
          case "Header":
            setMarkerPosition("378px");
            break;
          case "clientDiv":
            setMarkerPosition("505px");
            break;
          case "YzyDigital":
            setMarkerPosition("655px");
            break;
          case "Team":
            setMarkerPosition("793px");
            break;
          case "Skills":
            setMarkerPosition("915px");
            break;
          case "Blog":
            setMarkerPosition("1033px");
            break;
        }
      }
    });
  }

  useEffect(() => {
    if (ref !== undefined) {
      window.addEventListener("scroll", handleScrollMarkerPosition);
    }
    if (ref[0] == "blogg") {
      setMarkerPosition("1030px");
      return;
    }
    if (ref[0] == "clientP") {
      setMarkerPosition("505px");
      return;
    }
    if (ref[0] == "Team") {
      setMarkerPosition("793px");
      return;
    }
    if (ref[0] == "articlee") {
      setMarkerPosition("1033px");
      return;
    }
  }, []);
  */