import {Module} from "i18next";

const resourcesToBackend = (res:any) => ({
    type: 'backend',
    init (services:any, backendOptions:any, i18nextOptions:any) { /* use services and options */ },
    read (language:any, namespace:any, callback:any) {
        if (typeof res === 'function') { // in case someone wants to customize the loading...
            if (res.length < 3) {
                // no callback
                try {
                    const r = res(language, namespace)
                    if (r && typeof r.then === 'function') {
                        // promise
                        r.then((data:any) => callback(null, (data && data.default) || data)).catch(callback)
                    } else {
                        // sync
                        callback(null, r)
                    }
                } catch (err) {
                    callback(err)
                }
                return
            }
            // normal with callback
            res(language, namespace, callback)
            return
        }
        callback(null, res && res[language] && res[language][namespace])
    }
})

export default resourcesToBackend ;